import i18next from 'i18next';

i18next.addResources('es', 'DetailModal', {
  title: 'Datos del turno',
  dateTitle: 'Fecha y hora',
  save: 'Guardar',
  cancel: 'Cancelar turno',
  confirm: 'Confirmar turno',
  sendRDS: 'Enviar a Pastoral Digital',
  baptizedTitle: 'Datos del bautizado',
  tutorTitle: 'Datos de padres / tutor o encargado',
  contactTitle: 'Datos de contacto',
  godParentsTitle: 'Datos de padrinos',
  name: 'Nombre',
  lastName: 'Apellido',
  pid: 'DNI',
  phone: 'Teléfono',
  email: 'Email',
  closeRDS: 'Cancelar',
  rdsTitle: 'Enviar a Pastoral Digital',
  rdsInfo: 'Información del bautismo',
  minister: 'Ministro',
  rector: 'Párroco',
  saveSuccess: 'Los datos han sido guardados correctamente',
  confirmSuccess: 'El turno ha sido confirmado',
  cancelSuccess: 'El turno ha sido cancelado',
  sendSuccess: 'Los datos han sido enviados correctamente',
  accept: 'Aceptar',
  birthDate: 'Fecha de nacimiento',
  country: 'País',
  province: 'Provincia',
  locality: 'Localidad',
});
