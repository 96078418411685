import { FC } from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({ children, className, type, ...props }) => (
  <button {...props} type={type} className={className}>
    {children}
  </button>
);

const BaseButton = styled(Button)`
  border: none;
  border-radius: 200px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 140px;
  outline: none;
  padding: 1.1em 0.9em;
  text-transform: uppercase;
  width: 100%;
  :disabled {
    opacity: 0.5;
  }
`;

BaseButton.defaultProps = {
  type: 'button',
};

export const BlueButton = styled(BaseButton)`
  background-color: ${colors.blue};
  color: ${colors.white};
`;

export const BlueDarkButton = styled(BaseButton)`
  background-color: ${colors.darkBlue};
  color: ${colors.white};
`;
